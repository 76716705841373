import { useConfig } from 'contexts/config';
import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';

import { OIDCDiscoveryVerifier } from 'components/OIDCDiscoveryVerifier';
import TenantRouter from 'components/TenantRouter';
import { OIDC } from 'shared/utils/oidc';

export const Main = () => {
  const config = useConfig();

  const authConfig = {
    userManager: OIDC.getInstance({
      authority: config.oidc.client?.uris.authority,
      post_logout_redirect_uri: config.oidc.client?.uris.postLogoutRedirectUri,
      redirect_uri: config.oidc.client?.uris.redirectUri,
      metadataUrl: `${config.oidc.client?.uris.authority}/.well-known/openid-configuration`,
      client_id: config.oidc.client?.id,
    }),
    onSigninCallback: () => window.history.replaceState({}, document.title, window.location.pathname),
  };

  return (
    <OIDCDiscoveryVerifier>
      <AuthProvider userManager={authConfig.userManager} onSigninCallback={authConfig.onSigninCallback}>
        <BrowserRouter>
          <TenantRouter />
        </BrowserRouter>
      </AuthProvider>
    </OIDCDiscoveryVerifier>
  );
};
