import { Box, Flex } from '@chakra-ui/react';
import { useTheme } from '@salire-as/ui';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ChakraGoBack } from 'components';
import { calcFullHeight } from 'utils/calcFullHeight';

const ErrorPage = ({ error }: FallbackProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    console.error('Error: ', error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <Flex w="100%" h={calcFullHeight('100vh')} justifyContent="center" alignItems="center" flexDir="column" textAlign="center">
      <Box fontSize="lg" mb="2.5">
        {t('Error.title')}
      </Box>
      <Box mb="15px">{t('Error.description')}</Box>
      <Box>
        <ChakraGoBack colorScheme={theme.selectedTheme.id}>{t('common.goBack')}</ChakraGoBack>
      </Box>
    </Flex>
  );
};

export default ErrorPage;
