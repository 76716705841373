import { Box, Center } from '@chakra-ui/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import If from 'components/If';
import Loading from 'components/Loading';
import { OIDC } from 'shared/utils/oidc';
import { wrapPromise } from 'shared/utils/promise';

interface Props {}

export const OIDCDiscoveryVerifier = ({ children }: PropsWithChildren<Props>) => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [configError, setConfigError] = useState<Error | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const verify = async () => {
      const [, err] = await wrapPromise(OIDC.getInstance().metadataService.getMetadata());
      setIsVerifying(() => false);
      if (err) {
        setConfigError(() => err);
      }
    };
    verify();
  }, []);

  if (isVerifying || configError) {
    return (
      <Center height="full" gap="2" flexWrap="wrap" flexDirection="column">
        <If hidden={!!configError}>
          <Loading />
        </If>
        <If hidden={isVerifying}>
          <Box fontSize="lg">{t('Error.title')}</Box>
          <Box mb="15px">{t('Error.unknown')}</Box>
        </If>
      </Center>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
