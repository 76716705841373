import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { UIProvider, useTheme } from '@salire-as/ui';
import { first } from 'lodash';
import React, { PropsWithChildren, useEffect } from 'react';

interface Props {}

export const Theme = ({ children }: PropsWithChildren<Props>) => {
  const theme = useTheme();

  useEffect(() => {
    const themeId = document.location.hostname.includes('simpli') ? 'simpli' : 'ai-blue';
    theme.toggleTheme(first(theme.availableThemes.filter((item) => item.id === themeId))!);
  }, []);

  return (
    <>
      <ColorModeScript initialColorMode={theme.selectedTheme.config.initialColorMode} />
      <UIProvider theme={theme.selectedTheme}>
        <ChakraProvider theme={theme.selectedTheme} portalZIndex={99999}>
          {children}
        </ChakraProvider>
      </UIProvider>
    </>
  );
};
